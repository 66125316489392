const TableListScreens = {
    TABLE: {
      PATH: '/table-list',
      TITLE: 'Table List',
      TITLE_ALT: 'Table List',
      PERMISSION: 'tablelist.read',
    },
  }
  
  export default TableListScreens
  