export const Wording = {
  Button: {
    Save: 'Save',
    Cancel: 'Cancel',
    Delete: 'Delete',
    Back: 'Back',
    Add: 'Add',
    Yes: 'Exit',
    Stay: 'Stay',
    Ok: 'Ok',
    Sure: 'Sure',
    AddUser: 'Add User',
  },
  Status: {
    Active: 'Active',
    Inactive: 'Inactive',
  },
  Gender: {
    Male: 'Male',
  },
}

export const dumyData: any = {
  data: {
    response_output: {
      list: {
        content: [
          {
            user_name: 'Asep Sedunia',
            employee_name: 'Asep',
            branch: 'Ramen',
            branch_code: 'RTMC',
            type: 'Head Office',
            address: 'Jl. Raya Bogor, Jakarta',
            phone: '021-123456',
            version: 'POS Ramen',
            role: 'Admin',
            status: 'Active',
          },
        ],
        pagination: {
          page: 1,
          size: 10,
          total: 1,
        },
      },
    },
  },
}
