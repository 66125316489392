import {Suspense, lazy} from 'react'
import {Redirect, Route, Switch} from 'react-router-dom'
import {FallbackView} from '../components/Layout/FallbackView'
import TableListScreens from '../modules/table-list/Screeens'
import OrderScreens from '../modules/order/Screens'
import MemberScreens from '../modules/member/Screens'
import SalesRecapScreens from '../modules/sales-recapitulation/Screens'

export function PrivateRoutes() {
  const TableListPage = lazy(() => import('../modules/table-list/TableListRoutes'))
  const OrderPage = lazy(() => import('../modules/order/OrderRoutes'))
  const RegularMemberPage = lazy(()=> import('../modules/member/MemberRoutes'))
  const SalesRecapPage = lazy(()=> import('../modules/sales-recapitulation/SalesRecapRoutes'))

  return (
    <>
      <Suspense fallback={<FallbackView />}>
        <Switch>
          <Route path={TableListScreens.TABLE.PATH} component={TableListPage} />
          <Route path={OrderScreens.ORDER.PATH} component={OrderPage} />
          <Route path={MemberScreens.MEMBER.PATH} component={RegularMemberPage} />
          <Route path={SalesRecapScreens.SALES.PATH} component={SalesRecapPage} />
          <Redirect from='/auth' to='/table-list' />
          <Redirect exact from='/' to='/table-list' />
          <Redirect to='error/404' />
        </Switch>
      </Suspense>
    </>
  )
}
