import {QueryClient, QueryClientProvider} from '@tanstack/react-query'
import {FC, Suspense} from 'react'
import {BrowserRouter} from 'react-router-dom'
import {LayoutProvider} from './context/LayoutContext'
import {LayoutSplashScreen} from './context/SplashScreenContext'
import AuthInit from './modules/auth/redux/AuthInit'
import {Routes} from './routers/Routes'

export const layoutConfig = {
  default: [
    "q w e r t y u i o p {bksp}",
    "@ a s d f g h j k l {enter}",
    "{lock} z x c v b n m ! ? {lock}",
    "{shift} , {space} . {shift}"
  ],
  shift: [
    "` 1 2 3 4 5 6 7 8 9 0 - = {bksp}",
    "Q W E R T Y U I O P { } |",
    '@ A S D F G H J K L : " {enter}',
    "{lock} Z X C V B N M < > ? {lock}",
    "{shift} {space} {shift}"
  ]
}

export const displayButton = {
  '{bksp}': 'Delete',
  '{enter}': 'Enter',
  '{space}': ' ',
  '{shift}': 'Shift',
  '{lock}': 'CapsLock',
}

export const layoutNumberConfig = {
  default: [
    "1 2 3",
    "4 5 6",
    "7 8 9",
    ". 0 ,",
    "{bksp} {enter}"
  ],
  shift: [
    "1 2 3",
    "4 5 6",
    "7 8 9",
    "+ 0 {bksp}"
  ]
}
export const buttonTheme = [{
  class:'w-[60%]',
  buttons: '{space}'

}]

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
})

type Props = {
  basename?: string
}

const App: FC<Props> = ({basename}) => {
  return (
    <Suspense fallback={<LayoutSplashScreen />}>
      <BrowserRouter basename='/pos'>
        <QueryClientProvider client={queryClient}>
          <LayoutProvider>
            <AuthInit>
              <Routes />
            </AuthInit>
          </LayoutProvider>
        </QueryClientProvider>
      </BrowserRouter>
    </Suspense>
  )
}

export default App
