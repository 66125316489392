/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import { IFSalesMode } from 'src/app/components/Features/QuickService/QuickService.component';
import { buttonClass } from 'src/app/components/Features/Table/Table.component';
import { RootState } from 'src/setup';

const ListSalesMode: React.FC<{setSalesMode:(sales:IFSalesMode)=>void, salesMode:IFSalesMode, type: 'DINE_IN'| 'TAKE_AWAY'}> = ({setSalesMode, salesMode, type}) => {
    const [listSalesMode, setListSalesMode] = useState([])
    const dataBranch:  any = useSelector<RootState>(({auth}) => auth?.dataBranch, shallowEqual)

    useEffect(()=>{
        if(listSalesMode.length === 0) {
            const salesList = dataBranch?.sales_mode_id?.filter((item:IFSalesMode)=> item.status === 'ACTIVE' && type==='TAKE_AWAY' ? item.show_quick_service : item.show_dine_in)
            if(salesList?.length > 0) setListSalesMode(salesList)
        }
    }, [listSalesMode, dataBranch?.sales_mode])

    return (
        <div className='flex flex-col items-start relative '>
            <span className='text-sm mb-2'>Sales Mode</span>
            <div className=' gap-2 w-full grid grid-cols-3'>
                {listSalesMode && listSalesMode?.map((sales:any, index:number)=>{
                    return (
                        <button
                            key={index}
                            onClick={() => setSalesMode(sales)}
                            className={`${buttonClass(salesMode?.id === sales.id)} w-50`}
                            >
                                {sales.name.slice(0,11)}{sales.name.length > 7 ? '...':''}
                        </button>
                    )
                })
                }
            </div>
        </div>
    );
};

export default ListSalesMode;
