import {getAuthState} from '../hooks/getAuthState'

const baseUrl = process.env.REACT_APP_API_URL

export default function setupWebsocket(url: string, payload: any) {
  const {token} = getAuthState()
  const skipTokenSocket = ['login']
  const ws = new WebSocket(skipTokenSocket.includes(url) ? `${baseUrl}/${url}` : `${baseUrl}/${url}?token=${token ?? ''}`)
  ws.onopen = () => {
    ws.send(JSON.stringify(payload))
  }

  ws.onerror = (error) => {
    console.error('WebSocket Error:', error)
  }

  ws.onmessage = (event) => {
    return event
  }

  ws.onclose = (event) => {
    console.log('WebSocket Close:', url, event)
  }

  return ws
}
