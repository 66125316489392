import {FC, MouseEventHandler} from 'react'
import {cn} from 'src/app/utils/cn-utils'
import GIcon from '../../Libs/Icon/GIcon'

interface ModalHeaderProps {
  header?: string
  titleSize?: 'sm' | 'md'
  handleClose?: MouseEventHandler
  isBtnCloseHidden?: boolean
  subHeader?: string
}

const ModalHeader: FC<ModalHeaderProps> = ({
  header = 'Title',
  handleClose,
  titleSize = 'md',
  isBtnCloseHidden,
  subHeader
}) => {
  return (
    <div className='flex items-center bg-[#950F2C] rounded-tl w-full rounded-tr justify-between w-full p-6 font-medium border-b border-neutral-30'>
      <div
        className={cn(
          'flex flex-col items-start font-medium text-white',
          {'text-fs-6': titleSize === 'md'},
          {'text-fs-7': titleSize === 'sm'}
        )}
      >
        <div className='w-full'>
          {header}
        </div>
        {subHeader && (
          <div className="w-74 h-5 font-poppins font-normal text-sm leading-5 text-white flex-none order-1">
            {subHeader.trim()}
          </div>
        )}
      </div>

      {/* begin::Close */}
      {!isBtnCloseHidden && (
        <div
          data-testid='close-icon'
          onClick={handleClose}
          className='cursor-pointer text-neutral-60'
        >
          <GIcon icon='IconClose' className='w-6 h-6' />
        </div>
      )}
    </div>
  )
}

export default ModalHeader
