import { ChangeEventHandler, useCallback, useEffect, useMemo, useRef, useState } from "react"
import { KeyboardInput } from "react-simple-keyboard"
import { KeyboardVariant } from "./Keyboard.types"
import { buttonTheme, layoutVariant } from "./Keyboard.config"

export const useKeyboard = () => {
    const [inputs, setInputs] = useState<KeyboardInput>({})
    const [layoutName, setLayoutName] = useState('default')
    const [inputName, setInputName] = useState('default')
    const [keyboardOpen, setKeyboardOpen] = useState(false)
    const [keyboardVariant, setKeyboardVariant] = useState<KeyboardVariant>('DEFAULT')
    const keyboardRef = useRef<any>(null)

    const onChangeAll = useCallback((inputs: KeyboardInput) => {
        setInputs(prevInputs => ({ ...prevInputs, ...inputs }))
    }, [])

    const handleShift = useCallback(() => {
        setLayoutName(prevLayout => prevLayout === 'default' ? 'shift' : 'default')
    }, [])

    const onKeyPress = useCallback((button: string) => {
        if (button === '{shift}' || button === '{lock}') handleShift()
    }, [handleShift])

    const onFocus = useCallback((inputName: string) => {
        setInputName(inputName)
        setKeyboardOpen(true)
    }, [])

    const onChangeInput = useCallback<ChangeEventHandler<HTMLInputElement>>((event) => {
        const inputVal = event.target.value
        setInputs(prevInputs => ({
            ...prevInputs,
            [inputName]: inputVal,
        }))
        keyboardRef.current?.setInput(inputVal)
    }, [inputName])

    const getInputValue = useCallback((inputName: string) => inputs[inputName] ?? '', [inputs])

    const clearInputValue = useCallback((inputName: string) => {
        setInputs(prevInputs => ({
            ...prevInputs,
            [inputName]: '',
        }))
    }, [])

    const setVariant = useCallback((variant: KeyboardVariant) => {
        setKeyboardVariant(variant)
    }, [])

    useEffect(() => {
        const clickHandler = (e: MouseEvent) => {
            const target = e.target as HTMLElement
            const isNotKeyboardAndInput =
                target.nodeName !== 'INPUT' &&
                !target.classList.contains('hg-button') &&
                !target.classList.contains('hg-rows') &&
                !target.classList.contains('keyboard-container') &&
                !target.classList.contains('react-simple-keyboard')

            if (isNotKeyboardAndInput) {
                setKeyboardOpen(false)
            }
        }
        window.addEventListener('click', clickHandler)
        return () => window.removeEventListener('click', clickHandler)
    }, [])

    const value = useMemo(() => ({
        onChangeInput,
        getInputValue,
        onFocus,
        keyboardOpen,
        setKeyboardOpen,
        clearInputValue,
        setVariant,
    }), [onChangeInput, getInputValue, onFocus, keyboardOpen, clearInputValue, setVariant])

    const layout = layoutVariant[keyboardVariant]

    return {
        keyboardOpen,
        keyboardRef,
        inputName,
        layoutName,
        buttonTheme,
        onChangeAll,
        onKeyPress,
        layout,
        value
    }
}