
export const KEYBOARD_PRIMARY_COLOR = "#CF1E26"
export const KEYBOARD_SECONDARY_COLOR = "#FDEFE8"

export const layoutConfig = {
    default: [
        "q w e r t y u i o p {bksp}",
        "@ a s d f g h j k l {enter}",
        "{lock} z x c v b n m ! ? {lock}",
        "{shift} , {space} . {shift}"
    ],
    shift: [
        "` 1 2 3 4 5 6 7 8 9 0 - = {bksp}",
        "Q W E R T Y U I O P { } |",
        '@ A S D F G H J K L : " {enter}',
        "{lock} Z X C V B N M < > ? {lock}",
        "{shift} {space} {shift}"
    ]
}

export const layoutNumberConfig = {
    default: [
        "1 2 3",
        "4 5 6",
        "7 8 9",
        ". 0 ,",
        "{bksp} {enter}"
    ],
    shift: [
        "1 2 3",
        "4 5 6",
        "7 8 9",
        "+ 0 {bksp}"
    ]
}

export const displayButton = {
    '{bksp}': 'Delete',
    '{enter}': 'Enter',
    '{space}': ' ',
    '{shift}': 'Shift',
    '{lock}': 'CapsLock',
}


export const buttonTheme = [{
    class: 'w-[60%]',
    buttons: '{space}'
}]

export const layoutVariant = {
    DEFAULT: layoutConfig,
    NUMERIC: layoutNumberConfig,
  }
