import React, {useEffect, useState} from 'react'
import {IVisitor} from 'src/app/components/Features/Table/models/Table.model'
import {buttonClass} from 'src/app/components/Features/Table/Table.component'

const ListVisitorComponent: React.FC<{
  visitors: IVisitor | undefined
  listVisitor: IVisitor[] | null
  onSelecetVisitor: (visitor: IVisitor) => void
  type: 'DINE_IN' | 'TAKE_AWAY'
}> = ({visitors, listVisitor=[], onSelecetVisitor, type}) => {
  const [listVisitorType, setListVisitorType] = useState<IVisitor[]>([])
  useEffect(() => {
    if (listVisitor && listVisitor.length >= 0) {
      const listVisitorTypeHolder = listVisitor?.filter((item: IVisitor) =>
        item.status === 'ACTIVE' && type === 'TAKE_AWAY'
          ? item.show_quick_service
          : item.show_dine_in
      )
      setListVisitorType(listVisitorTypeHolder)
    }
  }, [])

  return (
    <div className='flex flex-col items-start'>
      {listVisitorType.length > 0 && <span className='text-sm mb-2'>Visitor Type</span>}
      <div className='gap-2 w-full grid grid-cols-3'>
        {listVisitorType &&
          listVisitorType.map((visitor: IVisitor, index: number) => {
            return (
              <button
                key={index}
                onClick={() => onSelecetVisitor(visitor)}
                className={`${buttonClass(visitors?.id === visitor.id)} w-50`}
              >
                {visitor.name.slice(0, 11)}
                {visitor.name.length > 7 ? '...' : ''}
              </button>
            )
          })}
      </div>
    </div>
  )
}

export default ListVisitorComponent
