const MemberScreens = {
  MEMBER: {
    PATH: '/regular-member',
    TITLE: 'Regular Member',
    TITLE_ALT: 'Member',
  },
  CREATE: {
    PATH: '/regular-member/add',
    TITLE: 'ADD Regular Member',
    TITLE_ALT: 'Create Member',
  },
  EDIT: {
    PATH: '/regular-member/edit/:id',
    TITLE: 'Edit Regular Member',
    TITLE_ALT: 'Edit Member',
  },
}

export default MemberScreens
