import {FC} from 'react'
import {Redirect, Route, Switch} from 'react-router-dom'
import {HeaderToast} from 'src/app/components/Libs'
import {HeaderToastProvider} from 'src/app/context/ToastContext'
import AuthScreens from './Screens'
import GuestWrapper from './components/GuestWrapper'
import MainWrapper from './components/MainWrapper'

const AuthRoutes: FC = () => {
  return (
    <HeaderToastProvider>
      <HeaderToast variant='FULL' />

      <GuestWrapper>
        <MainWrapper></MainWrapper>
        <Switch>
            <Route
              path={AuthScreens.LOGIN.PATH}
              component={AuthScreens.LOGIN.COMPONENT}
            />
            
            <Redirect from='/auth' exact={true} to='/auth/login' />
            <Redirect to='/auth/login' />
          </Switch>
      </GuestWrapper>
      
    </HeaderToastProvider>
  )
}

export default AuthRoutes
