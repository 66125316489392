import React from 'react';

interface NumberPadProps {
  onInput: (value: number) => void;
  onClear: () => void;
}

const NumberPad: React.FC<NumberPadProps> = ({ onInput, onClear }) => {
  const handleInput = (value: number) => {
    onInput(value);
  };

  return (
    <div className="flex flex-col items-center justify-center gap-4 w-full">
      <div className="grid grid-cols-3 gap-2 w-full">
        {[1, 2, 3, 4, 5, 6, 7, 8, 9].map((number) => (
          <button
            key={number}
            className="flex justify-center items-center p-2 w-24 h-12 bg-white border border-[#D0D5DD] rounded-lg"
            onClick={() => handleInput(number)}
          >
            {number}
          </button>
        ))}
        <button className="flex justify-center items-center p-2 w-24 h-12" disabled>
          
        </button>
        <button className="flex justify-center items-center p-2 w-24 h-12 bg-white border border-[#D0D5DD] rounded-lg" onClick={() => handleInput(0)}>
          0
        </button>
        <button className="flex justify-center items-center p-2 w-24 h-12 bg-white border border-[#D0D5DD] rounded-lg" onClick={onClear}>
          CLR
        </button>
      </div>
    </div>
  );
};

export default NumberPad;
