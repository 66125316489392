import {FC} from 'react'
import {shallowEqual, useSelector} from 'react-redux'
import {Redirect, Route, Switch} from 'react-router-dom'
import {RootState} from 'src/setup'
import MasterLayout from '../components/Layout/MasterLayout'
import AuthRoutes from '../modules/auth/AuthRoutes'
import {Logout} from '../modules/auth/page/Logout/Logout'
import Error404 from '../modules/error/error404'
import {PrivateRoutes} from './PrivateRoutes'

const Routes: FC = () => {
  const accessToken: any = useSelector<RootState>(
    ({auth}) => Boolean(auth.user),
    shallowEqual
  )

  return (
    <Switch>
      {!accessToken ? (
        <Route path='/auth'>
          <AuthRoutes />
        </Route>
      ) : (
        <Redirect from='/auth' to='/' />
      )}

      <Route path='/logout' component={Logout} />

      {!accessToken ? (
        <Redirect to='/auth/login' />
      ) : (
        <MasterLayout>
          <PrivateRoutes />

          <Route path='/error' component={Error404} />
        </MasterLayout>
      )}
    </Switch>
  )
}

export {Routes}
