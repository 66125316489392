import React, { useState } from 'react';
import { ArrowDown, RefreshSmall } from 'react-swm-icon-pack';


const LogComponent: React.FC<{data?: Array<any>;}> = ({data = []}) => {
    const [tabReport, setTabReport] = useState(0);
    const [tabService, setTabService] = useState(0);
    const [currentPage, setCurrentPage] = useState<number>(1);
    const [rowsPerPage, setRowsPerPage] = useState<number>(10);

    const indexOfLastRow = currentPage * rowsPerPage;
    const indexOfFirstRow = indexOfLastRow - rowsPerPage;
    const currentRows = data?.slice(indexOfFirstRow, indexOfLastRow); // Pastikan untuk mengakses data dengan benar
    return (
      <div className="flex flex-col grow h-full bg-white rounded-lg border border-[#EAECF0]">
          <div className='flex flex-col h-full'>
            <div className="flex flex-row px-5 py-6 border-b border-[#EAECF0] gap-3">
            <div className="flex flex-col max-w-[400px] gap-1">
              <div className="flex-none text-lg font-poppins">Log</div>
              <div className="text-sm text-[#667085]">Synchronize all authentication settings, including: User, User Role, and User Access</div>
            </div>
            <div className="flex flex-col grow items-end justify-center">
              <button className="flex flex-row rounded-lg px-3 py-4 bg-[#FDEFE8] gap-1 text-sm text-[#CF1E26]"><RefreshSmall color={"#CF1E26"}/> <span>Synchronize User</span></button>
            </div>
          </div>
          <div className="flex flex-row border border-[#EAECF0]">
            <button className={`flex-1 grow p-4 text-center text-base text-[#344054] ${tabReport === 0 && "border-b border-[#CF1E26]"}`} onClick={()=>setTabReport(0)}>
              Order Report
            </button>
            <button className={`flex-1 grow p-4 text-center text-base text-[#344054] ${tabReport === 1 && "border-b border-[#CF1E26]"}`} onClick={()=>setTabReport(1)}>
              Error Report
            </button>
          </div>
          {
            tabReport === 0 && (
              <>
                <div className="flex flex-row">
                  <button className={`flex px-4 py-6 text-center text-sm text-[#344054] ${tabService === 0 && "border-b border-[#CF1E26]"}`} onClick={()=>setTabService(0)}>
                    Full Service
                  </button>
                  <button className={`flex px-4 py-6 text-center text-sm text-[#344054] ${tabService === 1 && "border-b border-[#CF1E26]"}`} onClick={()=>setTabService(1)}>
                    Quick Service
                  </button>
                </div>
                <table className="min-w-full h-full">
                  <thead className='border-b border-[#EAECF0] bg-[#F9FAFB] text-sm text-left text-[#667085]'>
                    <tr>
                      <td className="px-5 py-6">Order Number</td>
                      <td className="px-5 py-6">
                        <div className="flex items-center gap-1">
                          <span>Date</span>
                          <ArrowDown size={16} color={"#667085"} />
                        </div>
                      </td><td className="px-5 py-6">
                        <div className="flex items-center gap-1">
                          <span>Pax Total</span>
                          <ArrowDown size={16} color={"#667085"} />
                        </div>
                      </td>
                      <td className="px-5 py-6">Member</td>
                      <td className="px-5 py-6">Additional Info</td><td className="px-5 py-6">
                        <div className="flex items-center gap-1">
                          <span>Grand Total</span>
                          <ArrowDown size={16} color={"#667085"} />
                        </div>
                      </td>
                    </tr>
                  </thead>
                  <tbody>
                    {currentRows.map((row, index) => (
                      <tr key={index} className="border-b border-[#EAECF0] text-xs text-left text-[#101828]">
                        <td className="px-5 py-6">{row.orderNumber}</td>
                        <td className="px-5 py-6">{row.date}</td>
                        <td className="px-5 py-6">{row.paxTotal}</td>
                        <td className="px-5 py-6">{row.member}</td>
                        <td className="px-5 py-6">{row.additionalInfo}</td>
                        <td className="px-5 py-6">{row.grandTotal}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </>
            )
          }
          {
            tabReport === 1 && (
              <>
                <table className="min-w-full h-full">
                  <thead className='border-b border-[#EAECF0] bg-[#F9FAFB] text-sm text-left text-[#667085]'>
                    <tr>
                      <td className="px-5 py-6">Order ID</td>
                      <td className="px-5 py-6">
                        <div className="flex items-center gap-1">
                          <span>Date Report</span>
                          <ArrowDown size={16} color={"#667085"} />
                        </div>
                      </td><td className="px-5 py-6">
                        <div className="flex items-center gap-1">
                          <span>Time</span>
                        </div>
                      </td>
                      <td className="px-5 py-6">Description</td>
                    </tr>
                  </thead>
                  <tbody>
                      <tr key={0} className="border-b border-[#EAECF0] text-xs text-left text-[#101828]">
                        <td className="px-5 py-6">SXRY1234567890</td>
                        <td className="px-5 py-6">20-02-2024</td>
                        <td className="px-5 py-6">14:28:16</td>
                        <td className="px-5 py-6">Error order</td>
                      </tr>
                  </tbody>
                </table>
              </>
            )
          }
        </div>
      </div>
  );
};

export default LogComponent;
