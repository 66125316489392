import axios from 'axios'
import ReactDOM from 'react-dom/client'
import {Provider} from 'react-redux'
import {PersistGate} from 'redux-persist/integration/react'
import App from './app/App'
import './app/plugins/i18n/i18n'
import {loadEnv} from './app/utils/env-utils'
import * as _redux from './setup'
import store, {persistor} from './setup/redux/Store'
import Loading from './app/components/Libs/Loading/Loading'
import './index.css'
import 'react-simple-keyboard/build/css/index.css';

loadEnv().finally(() => {
  const {PUBLIC_URL} = process.env
  _redux.setupAxios(axios, store())

  const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)

  root.render(
    <Provider store={store()}>
      <PersistGate persistor={persistor()} loading={<Loading />}>
        <App basename={PUBLIC_URL} />
      </PersistGate>
    </Provider>
  )
})
