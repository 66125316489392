import React, { useEffect } from 'react'
import {Card} from 'src/app/components/Libs'
import {toAbsoluteUrl} from 'src/app/utils/asset-helpers-utils'
import {imageError} from '../../../utils/broken-image'

const GuestWrapper: React.FC<{withTitle?: boolean; children?: React.ReactNode}> = ({
  withTitle = true,
  children,
}) => {
  const [mainChild, setMainChild] = React.useState<React.ReactNode>(null);
  
  const [asideChild, setAsideChild] = React.useState<React.ReactNode>(null);

  useEffect(() => {
    if (children && Array.isArray(children) && children.length > 0) {
      setMainChild(children[0]);
      setAsideChild(children[1]);
    } else {
      setMainChild(<div>No main content available</div>);
      setAsideChild(<div>No side content available</div>);
    }
  }, [children]);
  
  return (
    <div className="flex grow items-start p-6 bg-white shadow-lg rounded-xl">
      <Card.Root className="flex flex-column h-full grow m-6 pb-6 gap-5.5" style={{width:'60%'}}>
        {mainChild}
      </Card.Root>
      <Card.Root className="flex h-full max-h-[750px] grow px-5 justify-center items-center gap-14 bg-[#F9FAFB] rounded-lg max-w-fit" style={{width:'40%'}}>
        <div className="flex flex-col items-center p-0 gap-4 w-20 h-30">
          <div className="w-20 h-20 bg-[url('Logo Makan Ya!.png')]">
          <img
            src={toAbsoluteUrl('/media/logos/LogoRamen.svg')}
            alt='AIT'
            className='h-20'
            onError={(e) => imageError(e)}
          />
          </div>
          <div className="text-[#63052B] font-bold text-[32px]">POS</div>
        </div>
        {asideChild}
      </Card.Root>
    </div>
  )
}

export default GuestWrapper
