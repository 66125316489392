import {FC, MouseEventHandler} from 'react'
import {GButton} from '../../Libs/Button/Button.component'

interface ModalFooterProps {
  handleSuccess?: MouseEventHandler
  handleClose?: MouseEventHandler
  positiveLabel?: string
  negativeLabel?: string
  loading?: boolean
  disabled?: boolean
}

const OrderModalFooter: FC<ModalFooterProps> = ({
  handleSuccess,
  handleClose,
  positiveLabel,
  negativeLabel,
  loading,
  disabled
}) => {
  return (
    <div className='flex gap-3 justify-end items-center w-full px-6 py-5 border-t border-neutral-30'>
      <GButton
        onClick={handleClose}
        type='button'
        variant='OUTLINED'
        className='min-w-[138px] text-sm font-medium w-full'
        disabled={disabled}
        data-testid='close-button'
      >
        {negativeLabel ?? 'No'}
      </GButton>

      <GButton
        onClick={handleSuccess}
        className='min-w-[138px] text-sm font-medium w-full'
        type='button'
        loading={loading}
        disabled={disabled}
        data-testid='success-button'
      >
        {positiveLabel ?? 'Yes'}
      </GButton>
    </div>
  )
}

export default OrderModalFooter
