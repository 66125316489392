import store from '../../setup/redux/Store'

export const getAuthState = () => {
  const state = store().getState()
  const user = state.auth.user
  const token = user?.token_auth
  const dispatch = store().dispatch

  return {user, token, dispatch}
}
