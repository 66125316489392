import clsx from 'clsx'
import {FC} from 'react'
import {usePageData} from 'src/app/context/PageDataContext'
import {GBreadcrumbs} from '../../Libs'
import { shallowEqual, useSelector } from 'react-redux'
import { RootState } from 'src/setup'

const DefaultTitle: FC = () => {
  const {pageTitle} = usePageData()
  const dataBranch:  any = useSelector<RootState>(({auth}) => auth?.dataBranch, shallowEqual) 

  return (
    <div data-testid='breadcrumbs-component'>
      <div className={clsx('mb-0 flex items-start flex-col')}>
        {pageTitle && (
          <div
            className='mb-1 overflow-hidden font-medium break-all text-fs-7 text-ellipsis'
            style={{WebkitLineClamp: 1}}
          >
            {dataBranch?.code} - {dataBranch?.name}
          </div>
        )}

        <GBreadcrumbs />
      </div>
    </div>
  )
}

export {DefaultTitle}
