import clsx from 'clsx'
import React, { FC } from 'react';
import {useMasterLayout} from 'src/app/context/MasterContext'
import {KeyboardProvider} from '../Widgets/Keyboard/Keyboard.provider'

const ContentWrapper: FC<{children: React.ReactNode}> = ({children}) => {
  const {minimize} = useMasterLayout()

  return (
    <div
      data-testid='content-wrapper-component'
      className={clsx(
        `flex flex-col flex-[1_1_auto] min-w-0 transition-all duration-300`,
        {'lg:pl-[72px]': minimize},
        {'lg:pl-[264px]': !minimize}
      )}
    >
      <KeyboardProvider>{children}</KeyboardProvider>
    </div>
  )
}

export default ContentWrapper
