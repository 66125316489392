import {FC, MouseEventHandler} from 'react'

interface ModalHeaderProps {
  header?: string
  subHeader?: string | number | null
  titleSize?: 'sm' | 'md'
  handleClose?: MouseEventHandler
  isBtnCloseHidden?: boolean
  status?: 'DINE IN' | 'TAKE AWAY'| ''
}

const OrderModalHeader: FC<ModalHeaderProps> = ({
  header = 'Title',
  subHeader = '',
  status='',
  handleClose,
  titleSize = 'md',
  isBtnCloseHidden,
}) => {
  return (
    <div className="flex flex-row items-start p-4 gap-2 w-108 bg-[#950F2C] rounded-tl w-full rounded-tr">
      <div className="flex flex-col items-start p-0 gap-2 w-74 h-12.5 flex-none order-0 flex-grow">
        <div className="w-74 h-7 font-poppins font-medium text-lg leading-7 text-white flex-none order-0">
          {header}
        </div>
        {subHeader && (
          <div className="w-74 h-5 font-poppins font-normal text-sm leading-5 text-white flex-none order-1">
            Table number : <b>{subHeader}</b>
          </div>
        )}
      </div>
      <div className="flex flex-row items-start p-0 w-21 h-6 flex-none order-1">
        <div className="flex flex-row justify-center items-center p-0.5 px-2.5 gap-1 w-21 h-6 bg-primary-50 rounded-full">
          <div className="relative w-3 h-3">
            <div className="absolute w-2 h-2 left-0.5 top-0.5 bg-primary-500"></div>
          </div>
          <div className="w-12.25 h-5 font-poppins font-medium text-sm leading-5 text-center text-primary-600">
            <div className="flex flex-row items-center justify-center  gap-4 bg-[#FDEFE8] rounded-full" style={{width: 'auto', height: '30px'}}>
              <div className="relative h-3">
                <div className="absolute w-3 h-3 left-2 bg-[#CF1E26] rounded-full"></div>
              </div>
              <div className="w-full font-poppins font-medium text-sm leading-5 text-center text-[#B2152A] p-2">
                {status}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default OrderModalHeader
