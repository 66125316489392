import clsx from 'clsx'
import {format} from 'date-fns'
import React, {useEffect, useState} from 'react'
import {shallowEqual, useDispatch, useSelector} from 'react-redux'
import useWebSocket, {ReadyState} from 'react-use-websocket'
import LogComponent from 'src/app/components/Features/Log/Log.component'
import QuickService from 'src/app/components/Features/QuickService/QuickService.component'
import SalesList from 'src/app/components/Features/SalesList/SalesList.component'
import {ITableList} from 'src/app/components/Features/Table/models/Table.model'
import TableComponent from 'src/app/components/Features/Table/Table.component'
import {GButton} from 'src/app/components/Libs'
import {checkJson} from 'src/app/utils/cek-json-utils'
import {RootState} from 'src/setup'
import setupWebsocket from 'src/setup/websocket/SetupWebsocket'
import AuthRedux from '../redux/AuthRedux'
import {handleResponse} from 'src/setup/hooks/socketInterceptor'
import {ArrowLeft, ArrowRight} from 'react-swm-icon-pack'

const baseUrl = process.env.REACT_APP_API_URL

const data = [
  {
    orderNumber: 'RMY1234',
    date: '23-02-2024',
    paxTotal: 2,
    member: 'Non Member',
    additionalInfo: '-',
    grandTotal: 'Rp79.200',
  },
  {
    orderNumber: 'RMY1839',
    date: '23-02-2024',
    paxTotal: 3,
    member: 'Non Member',
    additionalInfo: '-',
    grandTotal: 'Rp113.500',
  },
  {
    orderNumber: 'RMY5678',
    date: '24-02-2024',
    paxTotal: 4,
    member: 'Member',
    additionalInfo: '-',
    grandTotal: 'Rp150.000',
  },
  {
    orderNumber: 'RMY9101',
    date: '24-02-2024',
    paxTotal: 2,
    member: 'Non Member',
    additionalInfo: '-',
    grandTotal: 'Rp80.000',
  },
  {
    orderNumber: 'RMY1112',
    date: '25-02-2024',
    paxTotal: 5,
    member: 'Member',
    additionalInfo: '-',
    grandTotal: 'Rp200.000',
  },
  {
    orderNumber: 'RMY1314',
    date: '25-02-2024',
    paxTotal: 3,
    member: 'Non Member',
    additionalInfo: '-',
    grandTotal: 'Rp120.000',
  },
  {
    orderNumber: 'RMY1516',
    date: '26-02-2024',
    paxTotal: 2,
    member: 'Non Member',
    additionalInfo: '-',
    grandTotal: 'Rp80.000',
  },
  {
    orderNumber: 'RMY1718',
    date: '26-02-2024',
    paxTotal: 4,
    member: 'Member',
    additionalInfo: '-',
    grandTotal: 'Rp160.000',
  },
  {
    orderNumber: 'RMY1920',
    date: '27-02-2024',
    paxTotal: 3,
    member: 'Non Member',
    additionalInfo: '-',
    grandTotal: 'Rp120.000',
  },
  {
    orderNumber: 'RMY2122',
    date: '27-02-2024',
    paxTotal: 2,
    member: 'Non Member',
    additionalInfo: '-',
    grandTotal: 'Rp80.000',
  },
  {
    orderNumber: 'RMY2324',
    date: '28-02-2024',
    paxTotal: 4,
    member: 'Member',
    additionalInfo: '-',
    grandTotal: 'Rp160.000',
  },
  {
    orderNumber: 'RMY2526',
    date: '28-02-2024',
    paxTotal: 3,
    member: 'Non Member',
    additionalInfo: '-',
    grandTotal: 'Rp120.000',
  },
  {
    orderNumber: 'RMY2728',
    date: '29-02-2024',
    paxTotal: 2,
    member: 'Non Member',
    additionalInfo: '-',
    grandTotal: 'Rp80.000',
  },
  {
    orderNumber: 'RMY2930',
    date: '29-02-2024',
    paxTotal: 4,
    member: 'Member',
    additionalInfo: '-',
    grandTotal: 'Rp160.000',
  },
  {
    orderNumber: 'RMY3132',
    date: '01-03-2024',
    paxTotal: 3,
    member: 'Non Member',
    additionalInfo: '-',
    grandTotal: 'Rp120.000',
  },
  {
    orderNumber: 'RMY3334',
    date: '01-03-2024',
    paxTotal: 2,
    member: 'Non Member',
    additionalInfo: '-',
    grandTotal: 'Rp80.000',
  },
  {
    orderNumber: 'RMY3536',
    date: '02-03-2024',
    paxTotal: 4,
    member: 'Member',
    additionalInfo: '-',
    grandTotal: 'Rp160.000',
  },
  {
    orderNumber: 'RMY3738',
    date: '02-03-2024',
    paxTotal: 3,
    member: 'Non Member',
    additionalInfo: '-',
    grandTotal: 'Rp120.000',
  },
  {
    orderNumber: 'RMY3940',
    date: '03-03-2024',
    paxTotal: 2,
    member: 'Non Member',
    additionalInfo: '-',
    grandTotal: 'Rp80.000',
  },
  {
    orderNumber: 'RMY4142',
    date: '03-03-2024',
    paxTotal: 4,
    member: 'Member',
    additionalInfo: '-',
    grandTotal: 'Rp160.000',
  },
  {
    orderNumber: 'RMY4344',
    date: '04-03-2024',
    paxTotal: 3,
    member: 'Non Member',
    additionalInfo: '-',
    grandTotal: 'Rp120.000',
  },
  {
    orderNumber: 'RMY4546',
    date: '04-03-2024',
    paxTotal: 2,
    member: 'Non Member',
    additionalInfo: '-',
    grandTotal: 'Rp80.000',
  },
  {
    orderNumber: 'RMY4748',
    date: '05-03-2024',
    paxTotal: 4,
    member: 'Member',
    additionalInfo: '-',
    grandTotal: 'Rp160.000',
  },
  {
    orderNumber: 'RMY4950',
    date: '05-03-2024',
    paxTotal: 3,
    member: 'Non Member',
    additionalInfo: '-',
    grandTotal: 'Rp120.000',
  },
]

enum Page {
  Indoor = 'Indoor',
  QuickService = 'QuickService',
  Log = 'Log',
  SalesList = 'SalesList',
}

const buttonClass = (isActive: boolean) =>
  `flex flex-row justify-center items-center px-4 py-3 gap-2 h-11 rounded-lg border text-sm whitespace-nowrap ${
    isActive ? 'bg-[#CF1E26] text-white' : 'bg-white text-[#CF1E26] border-[#CF1E26]'
  }`

const MainWrapper: React.FC<{withTitle?: boolean}> = ({withTitle = true}) => {
  //Public API that will echo messages sent to it back to the client
  const [socketUrl, setSocketUrl] = useState(`${baseUrl}/branch`)
  // component handler

  const {readyState, sendJsonMessage} = useWebSocket(socketUrl)
  const dispatch = useDispatch()
  const dataBranch: any = useSelector<RootState>(({auth}) => auth?.dataBranch, shallowEqual)
  const [selectedTable, setSelectedTable] = useState<ITableList | undefined>(
    dataBranch?.table_sections[0]
  )

  useEffect(() => {
    if (!dataBranch) {
      getData()
    }
  }, [dataBranch])

  useEffect(() => {
    if (readyState) dispatch(AuthRedux.actions.setConnectionStatus(connectionStatus))
  }, [readyState])

  const getData = () => {
    const payload = {
      type: 'GET',
    }
    const ws = setupWebsocket('branch', payload)
    ws.onmessage = (event) => {
      const response = checkJson(event.data) ? JSON.parse(event.data) : ''
      if (response?.response_schema?.response_code === '200') {
        dispatch(AuthRedux.actions.setBranch(response.response_output.detail))
        setSelectedTable(response.response_output.detail?.table_sections[0])
      } else {
        handleResponse(event)
      }
    }
    sendJsonMessage(payload)
  }

  const [currentPage, setCurrentPage] = useState<number>(1)
  const connectionStatus = {
    [ReadyState.CONNECTING]: 'Connecting',
    [ReadyState.OPEN]: 'Open',
    [ReadyState.CLOSING]: 'Closing',
    [ReadyState.CLOSED]: 'Closed',
    [ReadyState.UNINSTANTIATED]: 'Uninstantiated',
  }[readyState]

  const [page, setPage] = useState<Page>(Page.Indoor)
  
  const tableComponent = () => {
    return <TableComponent dataBranch={dataBranch} selecedSection={selectedTable} />
  }

  const quickServiceComponent = () => <QuickService dataBranch={dataBranch} />

  const logComponent = () => {
    return <LogComponent data={data} />
  }

  const salesListComponent = () => <SalesList data={data} />

  return (
    <div className='flex flex-col h-full gap-4'>
      <div style={{display: 'flex', flexDirection: 'column', padding: '0px', gap: '16px'}}>
        {withTitle && (
          <div className='flex flex-row'>
            <div style={{display: 'flex', flexDirection: 'column', padding: '0px', gap: '4px'}}>
              <div
                style={{
                  fontFamily: 'Poppins',
                  fontStyle: 'normal',
                  fontWeight: '500',
                  fontSize: '16px',
                  lineHeight: '24px',
                  color: '#101828',
                }}
              >
                {dataBranch?.name}
              </div>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                  padding: '0px',
                  gap: '8px',
                }}
              >
                <div
                  style={{
                    fontFamily: 'Poppins',
                    fontStyle: 'normal',
                    fontWeight: '500',
                    fontSize: '16px',
                    lineHeight: '24px',
                    color: '#667085',
                  }}
                >
                  Shift Date : {format(new Date(), 'dd-MM-yyyy')}
                </div>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'center',
                    alignItems: 'center',
                    padding: '2px 10px',
                    gap: '4px',
                    background: readyState !== ReadyState.OPEN ? '#FDEFE8' : '#EFFCE3',
                    borderRadius: '32px',
                  }}
                >
                  <div
                    style={{
                      fontFamily: 'Poppins',
                      fontStyle: 'normal',
                      fontWeight: '500',
                      fontSize: '14px',
                      lineHeight: '20px',
                      textAlign: 'center',
                      color: readyState !== ReadyState.OPEN ? '#CF1E26' : '#0B6417',
                    }}
                  >
                    {connectionStatus}
                  </div>
                </div>
              </div>
            </div>
            <div
              className='flex flex-row grow justify-end items-center'
              style={{
                fontFamily: 'Poppins',
                fontSize: '14px',
                lineHeight: '20px',
                color: '#98A2B3',
              }}
            >
              Page 1 of 1
            </div>
          </div>
        )}
        <div className='flex flex-row gap-3 flex-start p-0 justify-between'>
          <div
            id='carouselContainer'
            className='flex flex-10 flex-row gap-3 justify-between w-[80%]'
          >
            <div className='relative w-full flex flex-row justify-between gap-2 '>
              <GButton
                className={clsx(
                  'self-center flex flex-row justify-center items-center border-none bg-[#FDEFE8] rounded-lg w-[44px] h-[44px]'
                )}
                variant='UNSTYLED'
                size='small'
                data-testid='back-button'
                  onClick={() => {
                    const sectionList = document.getElementById('section-list')
                    if(sectionList) sectionList.scrollLeft -= 50
                  }}
              >
                <ArrowLeft color='#CF1E26' />
              </GButton>
              <div
                id='section-list'
                className='carousel-container overflow-x-hidden flex gap-4 w-full py-2'
              >
                <GButton
                  className={clsx(buttonClass(page === Page.QuickService))}
                  variant='UNSTYLED'
                  size='small'
                  data-testid='back-button'
                  onClick={() => setPage(Page.QuickService)}
                >
                  <span>Quick Service</span>
                </GButton>

                {dataBranch?.table_sections?.map(
                  (table: ITableList, index: number) => (
                    <GButton
                      key={index}
                      className={clsx(
                        buttonClass(page === Page.Indoor && selectedTable?.id === table.id)
                      )}
                      variant='UNSTYLED'
                      size='small'
                      data-testid='back-button'
                      onClick={() => {
                        setPage(Page.Indoor)
                        setSelectedTable(table)
                      }}
                    >
                      <span>{table.name}</span>
                    </GButton>
                  )
                )}
              </div>
              <GButton
                className={clsx(
                  ' self-center flex flex-row justify-center items-center border-none bg-[#FDEFE8] rounded-lg w-[44px] h-[44px]'
                )}
                variant='UNSTYLED'
                size='small'
                data-testid='back-button'
                onClick={() => {
                  const sectionList = document.getElementById('section-list')
                  if(sectionList) sectionList.scrollLeft += 50
                }}
              >
                <ArrowRight color='#CF1E26' />
              </GButton>
            </div>
            <div className='self-center flex-row gap-4 hidden'>
              <GButton
                className={clsx(
                  'flex flex-row justify-center items-center border-none bg-[#FDEFE8] rounded-lg w-[44px] h-[44px]',
                  {'opacity-40': currentPage === 1}
                )}
                variant='UNSTYLED'
                size='small'
                data-testid='back-button'
                onClick={() => {
                  if (currentPage > 0) {
                    setCurrentPage((e) => e - 1)
                  }
                }}
                disabled={currentPage === 1}
              >
                <ArrowLeft color='#CF1E26' />
              </GButton>
              {/* <span className='text-sm w-10 self-center'>
                {currentPage} of{' '}
                {Math.ceil((dataBranch?.table_sections ?? []).length / 5) === 0
                  ? 1
                  : Math.ceil((dataBranch?.table_sections ?? []).length / 5)}
              </span> */}
              <GButton
                className={clsx(
                  'flex flex-row justify-center items-center border-none bg-[#FDEFE8] rounded-lg w-[44px] h-[44px]',
                  {
                    'opacity-40':
                      currentPage === Math.ceil((dataBranch?.table_sections ?? [])?.length / 5),
                  }
                )}
                variant='UNSTYLED'
                size='small'
                data-testid='back-button'
                onClick={() => {
                  if (currentPage > 0) {
                    setCurrentPage((e) => e + 1)
                  }
                }}
                disabled={currentPage === Math.ceil((dataBranch?.table_sections ?? [])?.length / 5)}
              >
                <ArrowRight color='#CF1E26' />
              </GButton>
            </div>
          </div>
          <div className='flex flex-1 flex-row justify-end gap-3 h-11 self-center'>
            <button
              onClick={() => setPage(Page.Log)}
              className={`${buttonClass(page === Page.Log)} w-28`}
            >
              Log
            </button>
            <button
              onClick={() => setPage(Page.SalesList)}
              className={`${buttonClass(page === Page.SalesList)} w-28`}
            >
              Sales List
            </button>
          </div>
        </div>
      </div>
      {page === Page.Indoor && tableComponent()}
      {page === Page.QuickService && quickServiceComponent()}
      {page === Page.Log && logComponent()}
      {page === Page.SalesList && salesListComponent()}
    </div>
  )
}

export default MainWrapper
