import {ChangeEventHandler, createContext, PropsWithChildren, useContext} from 'react'
import ReactSimpleKeyboard from 'react-simple-keyboard'
import {KeyboardVariant} from './Keyboard.types'
import {buttonTheme, KEYBOARD_PRIMARY_COLOR, KEYBOARD_SECONDARY_COLOR} from './Keyboard.config'
import {useKeyboard} from './Keyboard.hook'

export type KeyboardProviderProps = PropsWithChildren

export type KeyboardContextModel = {
  onChangeInput: ChangeEventHandler<HTMLInputElement>
  getInputValue: (inputName: string) => string
  clearInputValue: (inputName: string) => void
  onFocus: (inputName: string) => void
  setVariant: (variant: KeyboardVariant) => void
}

const KeyboardContext = createContext<KeyboardContextModel | null>(null)

export const KeyboardProvider = ({children}: KeyboardProviderProps) => {
  const state = useKeyboard()

  return (
    <KeyboardContext.Provider value={state.value}>
      {children}
      {state.keyboardOpen && (
        <div className='keyboard-container fixed bottom-0 flex items-center justify-center left-0 right-0 z-[10000]'>
          <ReactSimpleKeyboard
            keyboardRef={(r) => (state.keyboardRef.current = r)}
            inputName={state.inputName}
            layoutName={state.layoutName}
            onChangeAll={state.onChangeAll}
            onKeyPress={state.onKeyPress}
            layout={state.layout}
            buttonTheme={buttonTheme}
            physicalKeyboardHighlight
            physicalKeyboardHighlightBgColor={KEYBOARD_SECONDARY_COLOR}
            physicalKeyboardHighlightTextColor={KEYBOARD_PRIMARY_COLOR}
          />
        </div>
      )}
    </KeyboardContext.Provider>
  )
}

export const useKeyboardContext = () => {
  return useContext(KeyboardContext)
}
