import {FC} from 'react'
import {Link} from 'react-router-dom'
import {usePageData} from 'src/app/context/PageDataContext'
import {cn} from 'src/app/utils/cn-utils'
import {trimBreadcrumbs} from './Breadcrumbs.utils'
import { shallowEqual, useSelector } from 'react-redux'
import { RootState } from 'src/setup'
import { format } from 'date-fns'

const Separator: FC = () => {
  return <span className='inline-block w-1 h-1 rounded-full shrink-0 bg-neutral-70'></span>
}

export const GBreadcrumbs: FC = () => {
  const {pageTitle, pageDescription, pageBreadcrumbs} = usePageData()

  const connectionStatus:  any = useSelector<RootState>(({auth}) => auth?.connectionStatus, shallowEqual)

  return (
    <div>
      {(pageDescription || (pageBreadcrumbs && pageBreadcrumbs.length > 0)) && (
        <ul className='flex flex-wrap items-center p-0 m-0 list-none text-fs-10'>
          {Array.from(pageBreadcrumbs ?? []).map((item) => (
            <li
              className={cn('flex items-center pr-2', {
                'text-neutral-80 dark:text-neutral-30': !item.isSeparator && item.isActive,
                'text-neutral-70 dark:text-neutral-20': !item.isSeparator && !item.isActive,
              })}
              key={`${item.path}`}
            >
              {!item.isSeparator ? (
                <Link
                  className='transition-all duration-200 text-neutral-70 hover:text-neutral-80'
                  to={item.path}
                >
                  {trimBreadcrumbs(item.title)}
                </Link>
              ) : (
                <Separator />
              )}
            </li>
          ))}
          <li className='flex items-center text-neutral-80 dark:text-neutral-20'>
            {/* {trimBreadcrumbs(pageDescription ?? pageTitle)}  */}
            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', padding: '0px', gap: '8px' }}>
              <div style={{ fontFamily: 'Poppins', fontStyle: 'normal', fontWeight: '500', fontSize: '16px', lineHeight: '24px', color: '#667085' }}>
                Shift Date : {format(new Date(), "dd-MM-yyyy")}
              </div>
              <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', padding: '2px 10px', gap: '4px', background: connectionStatus !== 'Open' ? '#FDEFE8':'#EFFCE3', borderRadius: '32px' }}>
                <div style={{ fontFamily: 'Poppins', fontStyle: 'normal', fontWeight: '500', fontSize: '14px', lineHeight: '20px', textAlign: 'center', color: connectionStatus !== 'Open' ? '#CF1E26':'#0B6417' }}>
                  {connectionStatus}
                </div>
              </div>
            </div>
          </li>
        </ul>
      )}
    </div>
  )
}
